$('.scrollTo').click(function(e) {
    e.preventDefault();
    $('.navbar-collapse.collapse.show').removeClass('show'); // pour fermer le burger menu sur mobiles
    var dest = $(this).attr('href').replace('#','').replace('/','');
    var offset = $('#'+dest).offset();
    $('html, body').animate({
		scrollTop: offset.top-$('header').outerHeight(true)+'px'
	}, 500);
	$('.scrollTo').removeClass('active');
	$(this).addClass('active');
});
$('.scrollDown').click(function(e) {
    e.preventDefault();
    $('.navbar-collapse.collapse.show').removeClass('show'); // pour fermer le burger menu sur mobiles
    $('html, body').animate({
		scrollTop: +($(window).outerHeight()-parseInt($('#header').css('height')))
	}, 500);
});