// JavaScript Document

function verif_email(element) {
	var verif = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ ;
	if ((verif.test($('#'+element).val())) || ($('#'+element).val()=='')) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}


function verif_email_obligatoire(element) {
	var verif = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_image(element) {
	var valeur = $('#'+element).val().replace(/^\s|\s$/g, ""); //trims string       
	var verif = /^[\w]+\.(png|gif|jpg|jpeg|PNG|GIF|JPG|JPEG)$/ ;
	if (verif.test(valeur)) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_doc(element) {
	const valeur = $('#'+element).val().replace(/^\s|\s$/g, ""); //trims string       
	var verif = /^[a-z][\w]?\.(pdf|dot|doc|docx|rtf|PDF|DOT|DOC|DOCX|RTF)$/ ;
	if ($('#'+element).val()>'')  {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_tel(element) {
	
		var verif = /^([0-9_\.\-\+\ ])*$/ ;
		if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
	
}
function verif_tel_33_obligatoire(element) {
	var verif = /^(\+33)([1-9])[0-9]{8}$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
	
}


function verif_tel_33(element) {
	var verif = /^(\+33)([1-9])[0-9]{8}$/ ;
	if (($('#'+element).val()=='') || (verif.test($('#'+element).val()))) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
	
}


function verif_url(element) {
var verif = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/ ;
if (($('#'+element).val()=='') || (verif.test($('#'+element).val()))) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_url_obligatoire(element) {
var verif = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/ ;
if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_tel_obligatoire(element) {
	var verif = /^([0-9_\.\-\+\ ])+$/ ;
	var verif2 = /^[\ ]+$/;
	if ((verif.test($('#'+element).val()))&&(!verif2.test($('#'+element).val()))) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_nombre(element) {
	var verif = /^([0-9]+)$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_code_postal(element) {
	var verif = /^([0-9]{5})$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}


function verif_double(element) {
	var verif = /^([0-9\.\,\ ]+)$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}


function verif_nombre_obligatoire(element) {
	var verif = /^([0-9]{1,5})$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_texte(element) {
	var verif = /^[\ ]+$/;
	if (($('#'+element).val()>'')&&(!verif.test($('#'+element).val()))) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_ckeditor(element) {
	const textbox_data = CKEDITOR.instances[element].getData();
    if (textbox_data ==='') {
	    $('#cke_'+element).addClass('is-invalid').removeClass('is-valid');
	} else {
		$('#cke_'+element).addClass('is-valid').removeClass('is-invalid');
	}
}

function verif_password(element) {
	var verif = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_@;?.!$€#%*<>]).{8,}$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_same_password(element) {
	if ($('#'+element).val()==$('#password').val()) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_date(element) {
	var verif = /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\d\d$/ ;
	if (verif.test($('#'+element).val())) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_coche(element) {
	if ($('#'+element).is(":checked")) {$('#'+element).addClass('is-valid');} else {$('#'+element).addClass('is-invalid');}
}

function verif_selectpicker(element) {
    $('#'+element).parent().find('button').addClass('form-control');
    if ($('#'+element).is('[multiple]')) {
        if ($('#'+element).val().length>0) {$('#'+element).parent().find('button').addClass('is-valid').removeClass('is-invalid');} else {$('#'+element).parent().find('button').addClass('is-invalid').removeClass('is-valid');}
    } else {
    	if ($('#'+element).val()>'0') {$('#'+element).parent().find('button').addClass('is-valid').removeClass('is-invalid');} else {$('#'+element).parent().find('button').addClass('is-invalid').removeClass('is-valid');}
    }
}

$('.verif').click(function(){

	$(this).removeClass('is-valid is-invalid').focus();

});

$('.verif_selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
  //verif_selectpicker($(e.currentTarget).attr('id'));
  check_all($(this).parents('.card-form'));
});

$('.radio-switch').on('switchChange.bootstrapSwitch', function (event, state) {
    check_all($(this).parents('.card-form'));
});


$('.verif').on("keyup blur", function() {
	$(this).removeClass('is-valid is-invalid');
	check_all($(this).parents('.card-form'));
});

function check_all(cardForm) {
		cardForm.find('.verif').each(function(){
            if($(this).hasClass('verif_texte')) {
              verif_texte($(this).attr("id")); 
            }
            
            if($(this).hasClass('verif_ckeditor')) {
              verif_ckeditor($(this).attr("id")); 
            }
            
            if($(this).hasClass('verif_code_postal')) {
              verif_code_postal($(this).attr("id")); 
            }
    		
    		
    		if($(this).hasClass('verif_email')) {
              verif_email($(this).attr("id")); 
            }
    		
    		
    		if($(this).hasClass('verif_email_obligatoire')) {
              verif_email_obligatoire($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_tel')) {
              verif_tel($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_tel_33')) {
              verif_tel_33($(this).attr("id")); 
            }
            
    		if($(this).hasClass('verif_tel_33_obligatoire')) {
              verif_tel_33_obligatoire($(this).attr("id")); 
            }
            
    		if($(this).hasClass('verif_tel_obligatoire')) {
              verif_tel_obligatoire($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_nombre')) {
              verif_nombre($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_double')) {
              verif_double($(this).attr("id")); 
            }
    		if($(this).hasClass('verif_nombre_obligatoire')) {
              verif_nombre_obligatoire($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_image')) {
              verif_image($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_doc')) {
              verif_doc($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_date')) {
              verif_date($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_password')) {
              verif_password($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_same_password')) {
              verif_same_password($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_coche')) {
              verif_coche($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_url')) {
              verif_url($(this).attr("id")); 
            }
    		
    		if($(this).hasClass('verif_selectpicker')) {
              verif_selectpicker($(this).attr("id")); 
            }
        });
    var Errors = cardForm.find('.is-invalid').length;
    if (Errors>0) {
	    cardForm.find('.next').addClass('disabled').addClass('btn-secondary').removeClass('btn-success').prop('disabled', true);
	} else { 
		cardForm.find('.next').removeClass('disabled').removeClass('btn-secondary').addClass('btn-success').prop('disabled', false);
	}
}

let form = $('.valid-form');
// On form submit take action, like an AJAX call
$(form).submit(function(e){
    if(this.checkValidity() == false) {
        e.preventDefault();
        e.stopPropagation();
    }
    $(this).addClass('was-validated');
});
