$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function(){

    //set separator height
    if ('nav.navbar.desktop'){
        var navheight = $("nav.navbar.desktop").css('height');
        $('body').css('padding-top', navheight);

        var mainheight




    }

    $(".lightGallery").lightGallery({
        thumbnail:true,
        selector: '.selector'
    });


    $('.same-height').matchHeight();
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $(".radio-switch").bootstrapSwitch({
        size : 'small',
    });

    if ($('.liste_triable').length) {
        $(".liste_triable").each(function() {
            liste=$(this);
            $(this).sortable({
                dropOnEmpty: false,
                axis:liste.attr("data-axis"),
                items: ".triable",
                helper : 'clone',
                connectWith: "[data-connection='"+$(this).attr('data-connection')+"']",
                update : function ()
                {
                    var order = $(this).sortable("serialize");
                    $.post(
                        $(this).attr("data-process"),
                        'prefixe='+$(this).attr("data-item")+'&dest_box='+$(this).attr('data-dest')+'&'+order
                    );
                }
            });
        });
    }

    $('.selectpicker').selectpicker({
        showSubtext : true,
        liveSearchNormalize : true,
        noneSelectedText : 'Aucune sélection',
        noneResultsText : 'Auncun résultat',
        style : '',
        styleBase : 'form-control',
    });
    
    // Styling Datatable
    var datatable = $('.datatable').DataTable( {
        "info":     false,
        "order": [[ 0, "desc" ]],
        "columnDefs": [ {
              "targets": 'no-sorting',
              "orderable": false,
            },
            { "bSearchable": true, "aTargets": [ 0,1 ] }
        ],
        language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher :",
            info:           "Affichage de l'élément START au END sur TOTAL éléments",
            infoEmpty:      "Affichage de l'élément 0 Ã  0 sur 0 éléments",
            infoFiltered:   "(filtré de MAX éléments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun élément à afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Précédent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        }
    });

    $('[rel="tooltip"]').tooltip();
    $('#hideseeksearch').hideseek({
        list: '.hideseek-list',
        highlight: true,
		ignore_accents: true,
        nodata: 'Aucun resultat'
    });

    $('.timepicker').timepicker({
        timeFormat: 'HH:mm',
        interval: 15,
        minTime: '06',
        maxTime: '20',
        defaultTime: true,
        startTime: '06:00',
        dynamic: false,
        dropdown: true,
        scrollbar: true
    });

    (function($){
        $.fn.datepicker.dates['fr'] = {
        days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
        daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
        daysMin: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
        today: "Aujourd'hui",
        monthsTitle: "Mois",
        clear: "Effacer",
        weekStart: 1,
        format: "dd/mm/yyyy"
        };
    }(jQuery));

    $('.datepicker').datepicker({
        language: 'fr',
        format: 'dd/mm/yyyy',
        "autoclose": true,
        uiLibrary: 'bootstrap4', 
        iconsLibrary: 'fontawesome', 
        size: 'large'
    });
    
    // hideseek sur dashboard
    $('.hideseek :checkbox').change(function(){
        if ($(this).data('sort') === 'calls') {
            $('.type_inter_2').slideToggle();
        } else if ($(this).data('sort') === 'emails') {
            $('.type_inter_3').slideToggle();
        } else {
            $('.type_inter_1').slideToggle();
        }
    });

    //toggle vertical nav clients
    $('.client-list .toggle-arrow').click(function(){
        $(this).toggleClass('fa-caret-left fa-caret-right')
        $('.client-list').toggleClass('closed opened');
        $('.client-list').children().hide();
        if ($('.opened').length){
            $('.client-list').children().fadeIn();
        }
        $('.toggle-arrow').show();
    });
}); 