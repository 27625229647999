require('./bootstrap');
require('jquery-ui-bundle');
window.swal = require('sweetalert2');
require ('datatables.net');
require('./main.js');
require('hideseek');
require('jquery.scrollto');
require('jquery-mousewheel');

require('datatables.net-bs4');
require('bootstrap-datepicker');
require('bootstrap-select');
require('../libs/bootstrap-switch/bootstrap-switch.min.js');
require('../libs/nasmo/nasmo-scrollTo.js');
require('../libs/jquery.matchHeight.js');
require('../libs/formulaires.js');
require('malihu-custom-scrollbar-plugin');
require('../../node_modules/jquery-timepicker/jquery.timepicker.js');
require('lightgallery');